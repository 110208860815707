import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import logo from "../assets/images/logo.png"
import { Helmet } from "react-helmet"



const TestAutomation = (props) => {

    useEffect(() => {
        typeof window != "undefined" && (
            window.fbAsyncInit = function () {
                if (typeof window.FB != "undefined") {
                    window.FB.init({
                        appId: '707551430855063',
                        cookie: true,
                        xfbml: true,
                        version: 'v16.0'
                    });

                    window.FB.AppEvents.logPageView();

                    console.log("nana")

                    window.FB.getLoginStatus(function(response) {
                        console.log(response)

                        // window.FB.login();

                        window.FB.login(
                            function(response) {
                              console.log(response);
                            },
                            {
                              config_id: '213580701198948', // configuration ID goes here
                              auth_type: 'code'   // must be set to 'code' for SUAT
                            }
                          );

                        
                    });
                }
            }
        )
    },[])

    return (
        <div className="errorPage">
            <Helmet>
                <html lang={"el"} />
                <title>404 Not Found</title>
                <meta name="robots" content="noindex, nofollow" />

                <script>
                    {typeof window != "undefined" && (
                        (function (d, s, id) {
                            var js, fjs = d.getElementsByTagName(s)[0];
                            if (d.getElementById(id)) { return; }
                            js = d.createElement(s); js.id = id;
                            js.src = "https://connect.facebook.net/en_US/sdk.js";
                            fjs.parentNode.insertBefore(js, fjs);
                        }(document, 'script', 'facebook-jssdk')))}
                </script>

                {/* <script>
                    {
                        typeof window != "undefined" && (
                            window.fbAsyncInit = function () {
                                if (typeof window.FB != "undefined") {
                                    window.FB.init({
                                        appId: '707551430855063',
                                        cookie: true,
                                        xfbml: true,
                                        version: 'v16.0'
                                    });

                                    window.FB.AppEvents.logPageView();

                                    // window.FB.getLoginStatus(function(response) {
                                    //     statusChangeCallback(response);
                                    // });
                                }
                            }
                        )
                    }
                </script> */}


            </Helmet>

            <div style={{
                position: "absolute",
                top: "15px",
                left: "15px",
                width: "auto"
            }}>
                <Link
                    to="/"
                    className="navbar-brand"
                >
                    <img id="nav-logo" src={logo} className={"w3-logo"} alt="logo" style={{
                        padding: "1rem",
                        backgroundColor: "white",
                        border: "3px solid orange"
                    }} />
                </Link>
            </div>
            TestAutomation
        </div>
    )
}

export default TestAutomation